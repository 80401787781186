<template>
  <div>
    <div class="q-pa-md">
      <!-- <q-table
        :dense="$q.screen.lt.md"
        title="封禁列表"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        row-key="steam_id"
        v-model:pagination="pagination"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="avatar_full" :props="props">
              <q-avatar rounded size="30px">
                <img :src="props.row.avatar_full" />
              </q-avatar>
            </q-td>
            <q-td key="persona_name" :props="props">
              {{ props.row.persona_name }}
            </q-td>
            <q-td key="steam_id" :props="props">
              {{ props.row.steam_id }}
            </q-td>
            <q-td key="profile_url" :props="props">
              <a :href="props.row.profile_url" target="_blank">{{
                props.row.profile_url
                  .substring(
                    props.row.profile_url
                      .substring(0, props.row.profile_url.lastIndexOf("/"))
                      .lastIndexOf("/")
                  )
                  .replaceAll("/", "")
              }}</a>
            </q-td>
            <q-td key="ban_by" :props="props">
              {{ props.row.ban_by }}
            </q-td>
            <q-td key="ban_length" :props="props">
              {{ getBanLength(props.row.ban_length) }}
            </q-td>
            <q-td key="remark" :props="props">
              {{ props.row.remark }}
            </q-td>
          </q-tr>
        </template>
        <template v-slot:top-right>
          <q-input
            borderless
            dense
            debounce="300"
            v-model="filter"
            placeholder="查找"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
      </q-table> -->
      <BanManagement/>
    </div>
  </div>
</template>

<script>
import BanManagement from '../../components/BanManagement.vue';
export default {
  name: "BanList",
  components: {
    BanManagement
  },
  data() {
    return {
      
    };
  },
  created() {
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
